import * as React from 'react';
import {BrowserRouter, BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LastSnapshotTable from "./components/Investor/LastSnapshotTable";

export default function App() {
    return (
        <Container>
            <Box sx={{ my: 4 }}>
                <BrowserRouter>
                    <Routes>
                        <Route index element={<LastSnapshotTable />} />
                    </Routes>
                </BrowserRouter>
            </Box>
        </Container>
    );
}