import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress, Typography
} from '@mui/material';
import {enqueueSnackbar} from "notistack";
import {useApi} from "../../context/ApiProvider";
import {LastSnapshot} from "../../types/LastSnapshot";

export const ProfitColumn = (props: { lastSnapshot: LastSnapshot }) => {
    const lastSnapshot = props.lastSnapshot;
    const profit = Number((lastSnapshot.last_snapshot_amount - lastSnapshot.total_invested).toFixed(2))
    const profitPercentage = Number((((lastSnapshot.last_snapshot_amount - lastSnapshot.total_invested) / lastSnapshot.total_invested) * 100).toFixed(2))

    if (profit > 0) {
        return <Typography color={'green'}>{profit} ({profitPercentage}%)</Typography>
    }

    return <Typography color={'red'}>{profit} ({profitPercentage}%)</Typography>
}

const LastSnapshotTable: React.FC = () => {
    const api = useApi()
    const [isLoading, setIsLoading] = useState(true);
    const [snapshotsData, setSnapshotsData] = useState<LastSnapshot[]>([]);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true)
        api?.get(`/snapshot/last`)
            .then(response => {
                if (!response.ok) {
                    enqueueSnackbar('Failed to fetch snapshots data!', {variant: 'error'})
                }

                return setSnapshotsData(response.body ?? []);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch snapshots data!', {variant: 'error'})
                setSnapshotsData([])
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    return (
        <div>
            <h2>Last snapshots</h2>
            {isLoading ? <LinearProgress /> : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Total Investido</TableCell>
                                <TableCell>Total Currente</TableCell>
                                <TableCell>Lucro (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {snapshotsData.map((row: LastSnapshot) => (
                                <TableRow key={row.investor_id}>
                                    <TableCell>{row.investor_id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.total_invested}</TableCell>
                                    <TableCell>{row.last_snapshot_amount}</TableCell>
                                    <TableCell>
                                        <ProfitColumn lastSnapshot={row} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>)}
        </div>
    );
}

export default LastSnapshotTable;